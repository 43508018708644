import { title, defaultFont } from "assets/jss/material-kit-react.js";

const cryptoContextStyle = (theme) => ({
  button: {
    ...defaultFont,
    fontSize: "13px"
  },
  section: {
    padding: "2.875rem 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: 0,
    fontSize: "2.438rem",
    textDecoration: "none",
    color: "#19434C",
    fontWeight: 700,
    textAlign: "left",  
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "@media (max-width: 575.98px)": {
      fontSize: "1.9rem",
    },
  },
  description: {
    color: "#0F121A",
    textAlign: "justify",
    fontSize: "14px",
    fontWeight: "400",
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "center",
    // },
  },
});

export default cryptoContextStyle;
