import { title, img, defaultFont } from "assets/jss/material-kit-react.js";

const serviceStyle = (theme) => ({
  section: {
    //paddingBottom: 70,
    //paddingLeft: 0,
    //paddingRight: 0,
    //paddingTop: 0,
    padding: "2.375rem 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    ...title,
    fontSize: "1.875rem",
    marginBottom: "1rem",
    marginTop: "1.875rem",
    textDecoration: "none",
    color: "#17424C",
    fontWeight: 600,
    "@media (max-width: 575.98px)": {
      marginTop: "0px !important",
      fontSize: "1.6rem",
    },
  },
  description: {
    color: "#5D5D5D",
    textAlign: "justify",
    fontSize: "12px",
    fontWeight: "400",
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "center",
    // },
  },
  img: {
    ...img,
  },
  button: {
    ...defaultFont,
    fontSize: "13px"
  },
  sectionContainer:{
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    },
  }
});

export default serviceStyle;
