import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";

import PerfectScrollbar from "react-perfect-scrollbar";

import styles from "assets/jss/material-kit-react/views/subPage.js";

import { useTranslation } from "react-i18next";
import classNames from "classnames";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function VisionPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="Kite Capital"
        fixed
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 600,
          color: "white",
        }}
        {...rest}
      />
      <div className={classes.imgVision}>
        {/* <div
          className={classNames(classes.container, classes.sliderContainer)}
        /> */}
      </div>
      <div className={classes.main}>
        <div className={classes.containerWrapper}>
          <GridContainer>
            <GridItem xs={12}>
              <h3 className={classes.title}>{t("views.vision.title")}</h3>
              <p className={classes.description}>
                {t("views.vision.heading_1")}
              </p>
              <p className={classes.description}>
                {t("views.vision.heading_2")}
              </p>
              <div className={classes.description}>
                <ul style={{ listStyleType: 'none', padding: "0" }}>
                  <li>{t("views.vision.heading_3")}
                    <ul style={{ listStyleType: 'none' }}>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_3_1")}</li>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_3_2")}</li>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_3_3")}</li>
                    </ul>
                  </li>
                  <li style={{ paddingTop: '1.2rem' }}>{t("views.vision.heading_6")}
                    <ul style={{ listStyleType: 'none' }}>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_6_1")}</li>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_6_2")}</li>
                    </ul>
                  </li>
                  <li style={{ paddingTop: '1.2rem' }}>{t("views.vision.heading_4")}
                    <ul style={{ listStyleType: 'none' }}>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_4_1")}</li>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_4_2")}</li>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_4_3")}</li>
                      <li className={classes.triangleListDescription}>{t("views.vision.heading_4_4")}</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <p className={classes.description}>
                {t("views.vision.heading_5")}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </>
  );
}
