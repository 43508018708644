import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";

import PerfectScrollbar from "react-perfect-scrollbar";

import styles from "assets/jss/material-kit-react/views/subPage.js";

import { useTranslation } from "react-i18next";
import classNames from "classnames";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function CryptoContextPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="Kite Capital"
        fixed
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div className={classes.imgContext}>
        {/* <div
          className={classNames(classes.container, classes.sliderContainer)}
        /> */}
      </div>
      <div className={classNames(classes.main)}>
        <div className={classes.containerWrapper}>
          <GridContainer>
            <GridItem xs={12}>
              <h3 className={classes.title}>
                {t("views.cryptoContext.title")}
              </h3>
              <p className={classes.description}>
                {t("views.cryptoContext.heading_1")}
                <span style={{ color: '#20ad92' }}>{t("views.cryptoContext.highlight_1")} </span>
                {t("views.cryptoContext.heading_1_1")}
                <span style={{ color: '#20ad92' }}>{t("views.cryptoContext.highlight_2")} </span>
                {t("views.cryptoContext.heading_1_2")}
                <span style={{ color: '#20ad92' }}>{t("views.cryptoContext.highlight_3")} </span>
                {t("views.cryptoContext.heading_1_3")}
              </p>
              <br />
              <p className={classes.description}>
                {t("views.cryptoContext.heading_2")}
              </p>
              <br />
              <div className={classes.description}>
                {t("views.cryptoContext.heading_3")}
                <ul className={classes.listDescription}>
                  <li className={classes.listDescriptionItem}>{t("views.cryptoContext.heading_3_1")}</li>
                  <li className={classes.listDescriptionItem}>{t("views.cryptoContext.heading_3_2")}</li>
                </ul>
              </div>
              <p className={classes.description}>
                {t("views.cryptoContext.heading_4")}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </ >
  );
}
