import { img } from "assets/jss/material-kit-react";
import { container, title, defaultFont } from "assets/jss/material-kit-react.js";

const subPageStyle = (theme) => ({
  button: {
    ...defaultFont,
    fontSize: "13px"
  },
  container: {
    color: "#FFFFFF",
    ...container,
  },
  sliderContainer: {
    height: "60vh",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  containerWrapper: {
    color: "#FFFFFF",
    ...container,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    "@media (max-width: 575.98px)": {
    },
  },
  h100: {
    height: "100%",
  },
  slider: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "20%",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "35px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      marginTop: "0.875rem",
    },
  },
  subtitle: {
    ...title,
    fontSize: "26px",
    maxWidth: "500px",
  },
  smallTitle: {
    ...title,
    fontSize: "14px",
    margin: 0
  },
  description: {
    fontSize: "14px",
    color: '#0F121A',
    margin: '0'
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    padding: '1.15rem',
    paddingBottom: '2.3rem'
  },
  mainInverseBg: {
    background: "#19434c",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  listDescription: {
    listStyleType: 'square',
  },
  listDescriptionItem: {
    paddingTop: '3px',
    paddingBottom: '3px'
  },
  triangleListDescription: {
    paddingTop: '2px',
    paddingBottom: '2px',
    "&:before": {
      content: "''",
      borderColor: 'transparent #111',
      borderStyle: 'solid',
      borderWidth: '0.35em 0 0.35em 0.45em',
      display: 'block',
      height: 0,
      width: 0,
      left: '-1em',
      top: '1.1em',
      position: 'relative',
    }
  },
  tableContact: {
    color: '#0f121a',
    fontSize: '14px',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  tdTable: {
    width: '12%',
    [theme.breakpoints.down("sm")]: {
      width: '26%',
    },
  },
  verticalCenter: {
    verticalAlign: 'center',
  },
  paddingVertical: {
    paddingTop: '5px',
    paddingBottom: '15px',
  },
  gridItem: {
    paddingLeft: '0px',
    "@media (max-width: 575.98px)": {
      padding: "0 3px !important",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  input: {
    minHeight: '2rem',
    border: '1px solid #666666',
    backgroundColor: '#F1F1F1',
    padding: '5px'
  },
  textArea: {
    resize: 'none',
    width: 'inherit',
  },
  focusedInput: {
    backgroundColor: '#fff',
  },
  fullWidth: {
    width: '100%'
  },
  label: {
    lineHeight: '3',
  },
  recaptchaGrp: {
    padding: '2.5rem 1rem 0.7rem 0.3rem'
  },
  errors: {
    borderRadius: '2px',
    backgroundColor: '#bd0000',
    color: "#fff",
    margin: '12px 0',
    padding: '5px 15px 3px 25px'
  },
  info: {
    borderRadius: '2px',
    color: 'rgb(15, 18, 26)',
    // backgroundColor: '#20ad92',
    // color: "#fff",
    margin: '12px 0',
    // padding: '5px 15px 3px 25px',
    lineHeight: '30px'
  },
  img: {
    position: "relative",
    paddingTop: 68,
    backgroundSize: "cover",
  },
  imgContext: {
    position: "relative",
    paddingTop: 68,
    backgroundSize: "cover",
    backgroundImage: `url('${require("assets/img/Boi-canh-01-min.jpg").default}')`,
    height: "55vh",
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 575.98px)": {
      height: "auto",
      content: `url('${require("assets/img/Boi-canh-M-01-min.jpg").default}')`
    },
  },
  imgVision: {
    position: "relative",
    paddingTop: 68,
    backgroundSize: "cover",
    backgroundImage: `url('${require("assets/img/Tam-nhin-01-min.jpg").default}')`,
    height: "55vh",
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 575.98px)": {
      height: "auto",
      content: `url('${require("assets/img/Tam-nhin-M-01-min.jpg").default}')`
    },
  },
  imgService: {
    position: "relative",
    paddingTop: 68,
    backgroundSize: "cover",
    backgroundImage: `url('${require("assets/img/Dich-vu-01-min.jpg").default}')`,
    height: "55vh",
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 575.98px)": {
      height: "auto",
      content: `url('${require("assets/img/Dich-vu-M-01-min.jpg").default}')`
    },
  },
  imgAboutUs: {
    position: "relative",
    paddingTop: 68,
    backgroundSize: "cover",
    backgroundImage: `url('${require("assets/img/Ve-chung-toi-01-min.jpg").default}')`,
    height: "55vh",
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 575.98px)": {
      height: "auto",
      content: `url('${require("assets/img/Ve-chung-toi-M-01-min.jpg").default}')`
    },
  },

});

export default subPageStyle;
