/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import LanguageDropdown from "./LanguageDropdown";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();

  const navLinkClass = classes.navLink + (props.home ? ' home-navLink' : '');

  function routeChange(routeName) {
    history.push(`/${routeName}`);
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink to="/crypto-context" className={navLinkClass} activeClassName={classes.navLinkSelected}>
          {t("views.landingPage.cryptocurrencyContext")}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink className={navLinkClass} to="/vision" activeClassName={classes.navLinkSelected}>
          {t("views.landingPage.vision")}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink to="/services" className={navLinkClass} activeClassName={classes.navLinkSelected}>
          {t("views.landingPage.services")}
        </NavLink>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <NavLink to="/blog" className={navLinkClass} activeClassName={classes.navLinkSelected}>
          {t("views.landingPage.blog")}
        </NavLink>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <NavLink to="/about" className={navLinkClass} activeClassName={classes.navLinkSelected}>
          {t("views.landingPage.aboutUs")}
        </NavLink>
      </ListItem>
      <LanguageDropdown />
    </List>
  );
}
