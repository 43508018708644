import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import { useMediaQuery } from 'react-responsive';

import styles from "assets/jss/material-kit-react/views/landingPageSections/cryptoContextStyle.js";

const useStyles = makeStyles(styles);

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export default function CryptoContextSection() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 865px)` });

  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            {!isMobile
              ? <GridItem xs={12} style={{ paddingBottom: '0px'}}>
                <h2 className={classes.title} >
                  {t("views.landingPage.sections.context.title")}
                </h2>
              </GridItem>
              : <GridItem xs={12} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <h2 className={classes.title} >
                  {t("views.landingPage.sections.context.title")}
                </h2>
              </GridItem>
            }
            {!isMobile
              ? <GridItem xs={12} style={{ paddingTop: '0px'}}>
                <Button
                  color="primary"
                  style={{
                    color: "#ffffff",
                    fontSize: "13px"
                  }}
                  className={classes.button}
                  onClick={() => history.push("/crypto-context")}
                >
                  {t("views.landingPage.readMore")}
                </Button>
              </GridItem>
              : <GridItem xs={12} sm={12} md={6} style={{ paddingTop: '0px', paddingBottom: '0px', marginTop: '-10px' }}>
                <p className={classes.description}>
                  {t("views.landingPage.sections.context.heading_1")}
                </p>
                <p className={classes.description}>
                  {t("views.landingPage.sections.context.heading_2")}
                </p>
              </GridItem>
            }
          </GridContainer>
        </GridItem>
        {!isMobile
          ? <GridItem xs={12} sm={12} md={6} >
            <p className={classes.description}>
              {t("views.landingPage.sections.context.heading_1")}
            </p>
            <p className={classes.description}>
              {t("views.landingPage.sections.context.heading_2")}
            </p>
          </GridItem>
          : <GridItem xs={12} style={{ paddingTop: '0px'}}>
            <Button
              color="primary"
              style={{
                color: "#ffffff",
                fontSize: "13px"
              }}
              className={classes.button}
              onClick={() => history.push("/crypto-context")}
            >
              {t("views.landingPage.readMore")}
            </Button>
          </GridItem>
        }
      </GridContainer>
    </div>
  );
}
