import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/aboutUsStyle.js";

import VisionImg from "assets/img/Ve-chung-toi-section.min.jpg";

const useStyles = makeStyles(styles);

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export default function AboutUsSection() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <img className={classes.img} src={VisionImg} alt="Vision" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <h3 className={classes.title}>{t("views.landingPage.aboutUs")}</h3>
          <p className={classes.description}>
            {t("views.landingPage.sections.aboutUs.heading_1")}
          </p>
          <Button
            color="primary"
            style={{
              color: "#ffffff",
            }}
            className={classes.button}
            onClick={() => history.push("/about")}
          >
            {t("views.landingPage.readMore")}
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
