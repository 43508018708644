import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = (theme) => ({
  container: {
    color: "#FFFFFF",
    ...container,
    height: 445,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  containerWrapper: {
    color: "#FFFFFF",
    ...container,
    paddingLeft: "2.5% !important",
    paddingRight: "2.5% !important",
    maxWidth: '100% !important',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px !important",
      paddingRight: "16px !important",
    },
  },
  sliderContainer: {
    height: "100%",
    textAlign: "left",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  slider: {
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "60px",
  },
  sliderTitle: {
    fontSize: "3.75rem",
    fontWeight: 800,
  },
  ml0: {
    marginLeft: "0"
  },
  h100: {
    height: "100%",
  },
  maxWidth60: {
    maxWidth: "60%",
  },
  ml40: {
    marginLeft: "40px",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "absolute",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    left: "4%",
    bottom: "10%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      fontSize: "3rem",
      paddingRight: "5%"
    },
    "@media (max-width: 992px)": {
      width: "100%",
      fontSize: "3rem",
      paddingRight: "3%"
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 575.98px)": {
      content: `url('${require("assets/img/Head-Banner-M-01-min.jpg").default}')`
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
  },
  mainInverseBg: {
    background: "#efefee",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
});

export default landingPageStyle;
