import axios from 'axios';

const $axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

$axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

$axios.interceptors.response.use(function (response) {
    switch (true) {
        case (response.status && response.status > 400):
            console.log(response.data || response);
            return null;
        case (response.status && response.status === 401):
            return null;
        default:
            return response.data || response;
    }
}, error => {
    if (error.response?.status === 401) {
        return;
    }
    console.log(error);
    return Promise.reject(error);
});

export default $axios;