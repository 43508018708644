import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
//import ProductSection from "./Sections/ProductSection.js";
//import TeamSection from "./Sections/TeamSection.js";
//import WorkSection from "./Sections/WorkSection.js";
//import ParticlesBg from "components/ParticlesBg/ParticlesBg.js";
import ParticlesBg from "particles-bg";
import CryptoContextSection from "./Sections/CryptoContextSection.js";
import VisionSection from "./Sections/VisionSection.js";
import ServiceSection from "./Sections/ServiceSection.js";
import AboutUsSection from "./Sections/AboutUsSection.js";
import QuoteSection from "./Sections/QuoteSection.js";

import PerfectScrollbar from "react-perfect-scrollbar";
// import QuoteImg from "assets/img/bg.jpeg";
import ParticleImage from "assets/img/Head-Banner-01-min.jpg";
import Particle from "./Sections/Particle.js";
import { useMediaQuery } from 'react-responsive';

//import { useTranslation } from "react-i18next";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  //const { t } = useTranslation();
  const [density, setDensity] = useState(100);
  const classes = useStyles();
  const { ...rest } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 865px)` })

  React.useEffect(() => {
    setDensity(isMobile ? 30 : 100);
  }, [isMobile]);

  return (
    <>
      <Header
        color="white"
        routes={dashboardRoutes}
        fixed
        brand="Kite Capital"
        rightLinks={<HeaderLinks home={true} />}
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div
        style={{
          position: "relative",
          paddingTop: 70,
          // backgroundImage:
          //   "linear-gradient(to right, #101219, #1a2631, #203c47, #27545a, #356c68)",
        }}
      >

        {/* <div className={classes.container}>
          <GridContainer spacing={5}
            className={classNames(
              classes.containerWrapper,
              classes.h100
            )}
          >
            <GridItem
              xs={12}
              sm={12}
              md={8}
              className={classes.sliderContainer}
            >
              <div className={classNames(classes.slider, classes.h100)}>
                <h1 className={classNames(classes.title, classes.sliderTitle)}>
                  Neque porro quisqua est qui dolorem
                </h1>
              </div>
            </GridItem>
          </GridContainer>
        </div> */}


        <img className={classes.img} src={ParticleImage} alt="Quote" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        <GridItem xs={4} sm={6} md={10} lg={12} style={{ position: "unset" }}>
          {/* <h1 className={classNames(classes.title, classes.sliderTitle)}>
            Neque porro quisqua est qui dolorem
          </h1>  */}
        </GridItem>
        < ParticlesBg
          type="cobweb"
          bg={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          color="#ffffff"
          num={density}
        />
        {/* <Particle></Particle> */}
      </div>
      <div className={classNames(classes.main)}>
        <div className={classes.containerWrapper}>
          <CryptoContextSection />
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainInverseBg)}>
        <div className={classes.containerWrapper}>
          <VisionSection />
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainInverseBg)}>
        <div className={classes.containerWrapper}>
          <ServiceSection />
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainInverseBg)}>
        <div className={classes.containerWrapper}>
          <AboutUsSection />
        </div>
      </div>
      <div className={classNames(classes.main)}>
        <div className={classes.containerWrapper}>
          <QuoteSection />
        </div>
      </div>
      <Footer />
    </>
  );
}
