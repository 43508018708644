import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "./i18n";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
//import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import CryptoContextPage from "views/CryptoContextPage/CryptoContextPage";
import VisionPage from "views/VisionPage/VisionPage";
import ServicesPage from "views/ServicesPage/ServicesPage";
//import BlogPage from "views/BlogPage/BlogPage";
import AboutUsPage from "views/AboutUsPage/AboutUsPage";
import ScrollToTop from "components/ScrollToTop/ScrollToTop.js";
//import ProfilePage from "views/ProfilePage/ProfilePage.js";
//import LoginPage from "views/LoginPage/LoginPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Suspense fallback="loading">
      <Router history={hist}>
        <ScrollToTop />
        <Switch>
          <Route path="/crypto-context" component={CryptoContextPage} />
          <Route path="/vision" component={VisionPage} />
          <Route path="/services" component={ServicesPage} />
          {/* <Route path="/blog" component={BlogPage} /> */}
          <Route path="/about" component={AboutUsPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
  </Suspense>,
  document.getElementById("root")
);
