import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReCaptcha } from 'react-recaptcha-google'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Phone from "@material-ui/icons/Phone";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/subPage.js";
import cssStyles from "views/AboutUsPage/ContactSection.scss";

// import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import $axios from 'axios-conf';
import classNames from "classnames";
import emailjs from 'emailjs-com';
// import emailjs from '@emailjs/browser';

const useStyles = makeStyles(styles);
const useRawCssStyles = makeStyles(cssStyles);

export default function ContactSection(props) {
  const [invalidForm, setInvalidForm] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [focusingInput, setFocusingInput] = useState('');
  const [messages, setMessages] = useState([]);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [content, setContent] = useState('');

  const { t } = useTranslation();
  const classes = useStyles();
  const rawCssClasses = useRawCssStyles();
  const captchaSiteKey = "6LfsKFwbAAAAAPSYuTScrSXjYcIGYK-SG9g2q0n-";

  useEffect(() => {
    reset();
  }, [])

  const handleFocus = (fieldName) => { setFocusingInput(fieldName) }
  const handleBlur = () => { setFocusingInput(''); }

  const reset = () => {
    setInvalidForm(true);
    setIsSubmitting(false);
    setMessages([]);
    setFullName('');
    setEmail('');
    setPhoneNo('');
    setContent('');
  }

  // const verifyCallback = async (recaptchaToken) => {
  //   const { data } = await $axios.post(`contact/captcha`, { token: recaptchaToken });
  //   setInvalidForm(!data);
  //   return data ?? false;
  // }

  const isValidForm = () => {
    let isValid = true;
    let msgs = [];
    if (!fullName) {
      msgs.push({ isError: true, content: t("views.aboutUs.contact.invalidFullName") });
      isValid = false;
    }
    if (!email) {
      msgs.push({ isError: true, content: t("views.aboutUs.contact.invalidEmail") });
      isValid = false;
    }
    if (!phoneNo) {
      msgs.push({ isError: true, content: t("views.aboutUs.contact.invalidPhoneNo") });
      isValid = false;
    }
    setMessages(msgs);
    return isValid;
  }

  const requestContact = async (e) => {
    if (!isSubmitting) {
      e.preventDefault();
      setIsSubmitting(true);

      if (isValidForm()) {
        // const contactData = {
        //   fullName: fullName,
        //   email: email,
        //   phoneNo: phoneNo,
        //   content: content,
        // };
        // const { data } = await $axios.post(`contact`, contactData);
        let serviceId = 'service_qordpaq';
        let templateId = 'template_j08nygo';
        let userId = 'Yj-2wN9otiVsq0iiQ';
        const templateParams = {
          serviceID: serviceId,
          templateID: templateId,
          userID: userId,
          templateParams: {
            NAME: fullName,
            EMAIL: email,
            PHONE_NUMBER: phoneNo,
            CONTENT: content,
          },
        };

        // Send email using EmailJS
        emailjs.send(
          templateParams.serviceID,
          templateParams.templateID,
          templateParams.templateParams,
          templateParams.userID
        ).then((response) => {
          reset();
          setMessages([{ isError: false, content: t("views.aboutUs.contact.sendOK") }]);
          setIsSubmitted(true);
        }).catch((error) => {
          setMessages([{ isError: true, content: t("views.aboutUs.contact.sendFailed") }]);
          setIsSubmitting(false);
          console.error(error);
        });
      }
      else setIsSubmitting(false);
    }
  }

  return (
    <GridItem xs={12} sm={12}>
      <p className={classes.subtitle}> {t("views.aboutUs.contact.contactTitle")}</p>
      {messages ?
        messages.map((msg, idx) => <p className={msg.isError ? classes.errors : classes.info} key={idx}> {msg.content} </p>)
        : <></>}
      {isSubmitted ? <></> :
        <form onSubmit={requestContact} >
          <GridContainer spacing={2} container direction="row" justify="space-evenly" alignItems="center" className={classes.paddingVertical}>
            <GridItem xs={6} sm={4} md={4}>
              <GridContainer spacing={2} container direction="column" justify="space-evenly" alignItems="stretch" >
                <GridItem className={classes.gridItem}>
                  <label className={classes.label}>{t("views.aboutUs.contact.fullName")} </label>
                </GridItem>
                <GridItem className={classes.gridItem}>
                  <input type="text" value={fullName}
                    style={{ borderRadius: 'unset' }}
                    onFocus={() => handleFocus('fullName')}
                    onBlur={handleBlur}
                    onChange={(e) => { setFullName(e.target.value) }}
                    className={classNames({
                      [classes.input]: true,
                      [classes.fullWidth]: true,
                      [classes.focusedInput]: focusingInput == 'fullName',
                    })}>
                  </input>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={6} sm={4} md={4}>
              <GridContainer spacing={2} container direction="column" justify="space-evenly" alignItems="stretch" >
                <GridItem className={classes.gridItem}>
                  <label className={classes.label}>{t("views.aboutUs.contact.email")} </label>
                </GridItem>
                <GridItem className={classes.gridItem}>
                  <input type="email" value={email}
                    style={{ borderRadius: 'unset' }}
                    onFocus={() => handleFocus('email')}
                    onBlur={handleBlur}
                    onChange={(e) => { setEmail(e.target.value) }}
                    className={classNames({
                      [classes.input]: true,
                      [classes.fullWidth]: true,
                      [classes.focusedInput]: focusingInput == 'email',
                    })}>
                  </input>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <GridContainer spacing={2} container direction="column" justify="space-evenly" alignItems="stretch" >
                <GridItem className={classes.gridItem}>
                  <label className={classes.label}>{t("views.aboutUs.contact.phoneNo")} </label>
                </GridItem>
                <GridItem className={classes.gridItem}>
                  <input type="text" value={phoneNo}
                    style={{ borderRadius: 'unset' }}
                    onFocus={() => handleFocus('phoneNo')}
                    onBlur={handleBlur}
                    onChange={(e) => { setPhoneNo(e.target.value) }}
                    className={classNames({
                      [classes.input]: true,
                      [classes.fullWidth]: true,
                      [classes.focusedInput]: focusingInput == 'phoneNo',
                    })}>
                  </input>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer spacing={2} container direction="row" justify="space-evenly" alignItems="flex-start" className={classes.paddingVertical}>
            <GridItem className={classes.gridItem}>
              <label className={classes.label}>{t("views.aboutUs.contact.content")} </label>
            </GridItem>
            <GridItem xs={12} sm={8} >
              <GridContainer spacing={2} container direction="column" justify="space-evenly" alignItems="stretch" >

                <GridItem className={classes.gridItem}>
                  <textarea value={content} rows="12" resize="none"
                    style={{ borderRadius: 'unset' }}
                    onFocus={() => handleFocus('content')}
                    onBlur={handleBlur}
                    onChange={(e) => { setContent(e.target.value) }}
                    className={classNames({
                      [classes.input]: true,
                      [classes.textArea]: true,
                      [classes.fullWidth]: true,
                      [classes.focusedInput]: focusingInput == 'content',
                    })}>
                  </textarea>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={4}>
              <GridContainer spacing={2} container direction="column" justify="space-evenly" alignItems="stretch" >
                {/* <GridItem xs={12} className={classes.gridItem}>
                  <ReCaptcha
                    size="normal"
                    data-theme="dark"
                    render="explicit"
                    sitekey={captchaSiteKey}
                    onloadCallback={reset}
                    verifyCallback={verifyCallback}
                  />
                </GridItem> */}
                <GridItem xs={12} className={classes.gridItem}>
                  <Button className={classes.button} style={{ marginTop: '0px' }} type="submit" color="primary" disabled={isSubmitting} >
                    {isSubmitting ? t("views.aboutUs.contact.sending") : t("views.aboutUs.contact.send")}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </form>
      }
    </GridItem>
  );
}