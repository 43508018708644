import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = (theme) => ({
  companyInfo: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  listInfo: {
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
      textAlign: "center",
    },
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
  },
  footer: {
    // padding: "0.9375rem 0",
    fontSize: "13.5px",
    //textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
    backgroundColor: "#efefee"
  },
  title: {
    fontWeight: "500",
    //marginBottom: "30px",
    color: "#000000",
  },
  a: {
    color: '#0F121A',
    textDecoration: "none",
    backgroundColor: "transparent",
    "&:hover": {
      color: primaryColor,
    },
    fontWeight: "bold",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  container,
  containerWrapper: {
    ...container,
    maxWidth: "100% !important",
    padding: '40px 2.5% 60px 2.5% !important',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px !important",
      paddingRight: "16px !important",
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  listItem: {
    padding: 0,
    fontWeight: 500,
    margin: "4px 0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "block",
    },
  },
  listItemRight: {
    padding: 0,
    fontWeight: 500,
    margin: "4px 0px",
    display: "block",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "block",
    },
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  h5: {
    color: '#0F121A',
    margin: "4px 0px",
    fontSize: "13px",
    fontWeight: "500",
  },
  h5_2: {
    color: '#0F121A',
    margin: "4px 0px",
    fontSize: "13px",
    fontWeight: "500",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  alignAddress: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }
});
export default footerStyle;
