import { title, img } from "assets/jss/material-kit-react.js";

const quoteStyle = (theme) => ({
  section: {
    padding: "2rem 0 3rem 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "1.875rem",
    fontSize: "1.875rem",
    textDecoration: "none",
    color: "#19434C",
    fontWeight: 700,
    "@media (max-width: 575.98px)": {
      marginTop: "10px !important",
      fontSize: "1.6rem",
    },
  },
  description: {
    fontSize: "1.25rem",
    fontWeight: 500,
    color: "#0f121a",
  },
  img: {
    //minWidth: 800
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    "@media (max-width: 575.98px)": {
      // maxWidth: "none",
      // height: "50vh",
      // position: "relative",
      // left: "3rem"
    },
  },
  imgContent: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden'
  }
});

export default quoteStyle;
