import React, { useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/subPage.js";

import { useTranslation } from "react-i18next";
import classNames from "classnames";

import PerfectScrollbar from "react-perfect-scrollbar";
import ContactSection from "./ContactSection";
import { loadReCaptcha } from 'react-recaptcha-google'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUsPage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    loadReCaptcha();
  }, [])

  return (
    <>
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="Kite Capital"
        fixed
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div className={classes.imgAboutUs}>
        {/* <div
          className={classNames(classes.container, classes.sliderContainer)}
        /> */}
      </div>
      <div className={classes.main}>
        <div className={classes.containerWrapper}>
          <GridContainer spacing={2}>
            <GridItem xs={12}>
              <h3 className={classes.title}>
                {t("views.aboutUs.title")}
              </h3>
              <p className={classes.description}>
                {t("views.aboutUs.heading_1")}
              </p>
              <br />
              <p className={classes.description}>
                {t("views.aboutUs.heading_2")}
              </p>
              <br />
              <p className={classes.description}>
                {t("views.aboutUs.heading_3")}
              </p>
              <div className={classes.description}>
                <ul className={classes.listDescription}>
                  <li className={classes.listDescriptionItem}>{t("views.aboutUs.heading_3_1")} </li>
                  <li className={classes.listDescriptionItem}>{t("views.aboutUs.heading_3_2")} </li>
                  <li className={classes.listDescriptionItem}>{t("views.aboutUs.heading_3_3")} </li>
                  <li className={classes.listDescriptionItem}>{t("views.aboutUs.heading_3_4")} </li>
                </ul>
              </div>
            </GridItem>
            {/* <GridItem xs={12}>
              <hr style={{ width: '100%', background: '#3C4858' }} />
            </GridItem> */}
            {/* <GridItem xs={12}>
              <p className={classNames({ [classes.description]: true, [classes.paddingVertical]: true })} >
                {t("views.aboutUs.heading_4")}
              </p>
              <p className={classNames({ [classes.description]: true, [classes.smallTitle]: true, [classes.paddingVertical]: true })} >
                {t("views.aboutUs.title_1")}
              </p>
              <table className={classes.tableContact}>
                <tbody>
                  <tr>
                    <td className={classNames({ [classes.tdTable]: true, [classes.verticalCenter]: true })}>
                      {t("views.aboutUs.heading_5_1")}</td>
                    <td className={classes.verticalCenter} style={{ width: '2%' }}>
                      : </td>
                    <td style={{ width: '75%' }}> {t("views.aboutUs.heading_5_2")}</td>
                  </tr>
                  <tr>
                    <td className={classNames({ [classes.tdTable]: true, [classes.verticalCenter]: true })}>
                      {t("views.aboutUs.heading_6_1")}</td>
                    <td className={classes.verticalCenter} style={{ width: '2%' }}> : </td>
                    <td style={{ width: '75%' }}> {t("views.aboutUs.heading_6_2")}</td>
                  </tr>
                  <tr>
                    <td className={classNames({ [classes.tdTable]: true, [classes.verticalCenter]: true })}>
                      {t("views.aboutUs.heading_7_1")}</td>
                    <td className={classes.verticalCenter} style={{ width: '2%'}}> : </td>
                    <td style={{ width: '75%' }}> {t("views.aboutUs.heading_7_2")}</td>
                  </tr>
                </tbody>
              </table>
            </GridItem> */}
            <GridItem xs={12}>
              <hr style={{ width: '100%', background: '#3C4858' }} />
            </GridItem>
            <ContactSection></ContactSection>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </>
  );
}
