/*eslint-disable*/
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Language } from "@material-ui/icons";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import classNames from "classnames";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import i18next from "i18next";
import React from "react";

const useStyles = makeStyles(styles);

export default function LanguageDropdown() {
  const classes = useStyles();
  const [curLang, setCurLang] = React.useState("vi");

  const getLanguageName = React.useCallback(() => {
    switch (curLang) {
      case "en":
        return "EN";
      case "vi":
        return "VN";
      default:
        return "";
    }
  }, [curLang]);

  const changeLanguage = (lang) => {
    setCurLang(lang);
    i18next.changeLanguage(lang);
    localStorage.setItem("curLang", lang);
  };

  React.useEffect(() => {
    // const lang = localStorage.getItem("curLang");
    // if (lang) {
    //   changeLanguage(lang);
    // } else {
    //   changeLanguage("vi")
    // }
    changeLanguage("vi")
  }, []);

  return (
    <></>
    // <ListItem className={classes.listItem}>
    //   <CustomDropdown
    //     noLiPadding
    //     buttonText={getLanguageName()}
    //     buttonProps={{
    //       className: classNames(classes.navLink, classes.navLinkButton),
    //       color: "transparent",
    //     }}
    //     //buttonIcon={Language}
    //     dropdownList={[
    //       <a
    //         onClick={() => changeLanguage("vi")}
    //         className={classes.dropdownLink}
    //       >
    //         Tiếng Việt
    //       </a>,
    //       <a
    //         onClick={() => changeLanguage("en")}
    //         className={classes.dropdownLink}
    //       >
    //         English
    //       </a>,
    //     ]}
    //   />
    // </ListItem>
  )
}