import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/serviceStyle.js";

import ServiceImg from "assets/img/Dich-vu-section.min.jpg";

const useStyles = makeStyles(styles);

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export default function ServiceSection() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.section}>
      <GridContainer className={classes.sectionContainer}>
        <GridItem xs={12} sm={12} md={6}  >
          <h3 className={classes.title}>{t("views.landingPage.services")}</h3>
          <p className={classes.description}>
            {t("views.landingPage.sections.service.heading_1")}
          </p>
          <Button
            color="primary"
            style={{
              color: "#ffffff",
            }}
            className={classes.button}
            onClick={() => history.push("/services")}
          >
            {t("views.landingPage.readMore")}
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <img className={classes.img} src={ServiceImg} alt="Service" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
