import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/quoteStyle.js";

import QuoteImg from "assets/img/Quote-section.min.jpg";

const useStyles = makeStyles(styles);
import { useTranslation } from "react-i18next";

import classNames from "classnames";

export default function QuoteSection() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title, classes.textLeft)}>
            {t("views.landingPage.sections.quote.title")}
          </h2>
          <h3 className={classes.description} style={{ marginTop: "-15px", fontSize: "15px" }}>
            <i>{t("views.landingPage.sections.quote.heading_1")}</i>
          </h3>
          <h3 className={classes.description} >
            <i>{t("views.landingPage.sections.quote.heading_2")}</i>
          </h3>
          <h4 className={classes.description} style={{ fontSize: "12px", marginTop: "-10px" }}>
            <i>{t("views.landingPage.sections.quote.heading_2_1")}</i>
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {/* <div className={classes.imgContent}> */}
          <img className={classes.img} src={QuoteImg} alt="Quote" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          {/* </div> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}
