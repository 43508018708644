/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { NavLink, useHistory } from "react-router-dom";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.containerWrapper}>
        <GridContainer spacing={3}>
          <GridItem xs={12} sm={12} md={3} className={classes.listInfo}>
            <h4 className={classes.title}>{t("views.companyInfo.companyName")}</h4>
          </GridItem>
        </GridContainer>

        <GridContainer spacing={3}>
          <GridItem xs={12} sm={12} md={8} className={classes.listInfo}>
            <GridContainer spacing={3}>
              <GridItem xs={12} sm={12} md={6} className={classes.listInfo}>
                <h5 style={{ fontWeight: 500 }} className={classes.h5}>{t("views.companyInfo.building")}</h5>
                <div className={classes.alignAddress}>
                  <h5 className={classes.h5} style={{ marginRight: '5px' }}>{t("views.companyInfo.address_1")}</h5>
                  <h5 className={classes.h5}>{t("views.companyInfo.address_2")}</h5>
                </div>
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={3}>
                <h5 className={classes.h5} style={{fontWeight: 600}}>{t("views.companyInfo.hotline")}</h5>
                <h5 style={{ fontWeight: 500 }} className={classes.h5}>{t("views.companyInfo.phone")}</h5>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <h5 className={classes.h5} style={{fontWeight: 600}}>{t("views.companyInfo.fax")}</h5>
                <h5 style={{ fontWeight: 500 }} className={classes.h5}>{t("views.companyInfo.faxNumber")}</h5>
              </GridItem> */}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "-10px" }}>
            <GridContainer spacing={3}>
              <GridItem xs={12} sm={12} md={8} className={classes.listInfo}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <NavLink className={classes.a} to="/crypto-context">
                      {t("views.landingPage.cryptocurrencyContext")}
                    </NavLink>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <NavLink className={classes.a} to="/vision">
                      {t("views.landingPage.vision")}
                    </NavLink>
                  </ListItem>
                </List>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.listInfo} >
                <List className={classes.list}>
                  <ListItem className={classes.listItemRight}>
                    <NavLink className={classes.a} to="/services">
                      {t("views.landingPage.services")}
                    </NavLink>
                  </ListItem>
                  <ListItem className={classes.listItemRight}>
                    <NavLink className={classes.a} to="/about">
                      {t("views.landingPage.aboutUs")}
                    </NavLink>
                  </ListItem>
                </List>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>

      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
